import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/Service/api.service";

@Component({
  selector: "app-doc-pro-reports",
  templateUrl: "./doc-pro-reports.component.html",
  styleUrls: ["./doc-pro-reports.component.css"],
})

export class DocProReportsComponent implements OnInit {
  formTitle = "DocPro Reports";
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";
  loadingRecords = true;

  orgId: number = Number(this.cookie.get("orgId"));
  DATE = [];
  BRANCH_ID = ([] = []);
  DEPARTMENT_ID = [];
  DESIGNATION_ID = [];
  OWNER_ID = [];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) { }

  // REPORTS NAME AND STATUS
  reportStatus1: boolean = false;
  reportStatus2: boolean = false;
  reportStatus3: boolean = false;
  reportStatus4: boolean = false;
  reportStatus5: boolean = false;
  reportStatus6: boolean = false;

  reportName1: string = "User Wise Summary Report";
  reportName2: string = "Branch Summary Report";
  reportName3: string = "Department Summary Report";
  reportName4: string = "Designation Summary Report";
  reportName5: string = "Folder List Report";
  reportName6: string = "File List Report";

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateToDateFilter();
    this.getAllDocProBranches();
    this.getAllDocProDepartments();
    this.getAllDocProDesignations();
    this.getAllDocProUserName();

    this.getUserWiseSummaryReport();
    this.getBranchSummaryReport();
    this.getDepartmentSummaryReport();
    this.getDesignationSummaryReport();
    this.getFolderListReport();
    this.getFileListReport();
  }

  setDateToDateFilter() {
    let currentDate: Date = new Date();
    this.DATE[0] = currentDate;
    this.DATE[1] = currentDate;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  applyFilter() {
    if (
      this.DATE.length > 0 ||
      this.BRANCH_ID.length > 0 ||
      this.DEPARTMENT_ID.length > 0 ||
      this.DESIGNATION_ID.length > 0 ||
      this.OWNER_ID.length > 0
    ) {
      this.isFilterApplied = "primary";
    }

    else {
      this.isFilterApplied = "default";
    }

    this.filterClass = "filter-invisible";
    this.getUserWiseSummaryReport(false);
    this.getBranchSummaryReport(false);
    this.getDepartmentSummaryReport(false);
    this.getDesignationSummaryReport(false);
    this.getFolderListReport(false);
    this.getFileListReport(false);
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.DATE = [];
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.DESIGNATION_ID = [];
    this.OWNER_ID = [];
    this.setDateToDateFilter();
  }

  sort(sort: { key: string; value: string }): void {
    this.UserWiseSummaryReportSortKey = sort.key;
    this.UserWiseSummaryReportSortValue = sort.value;
    this.getUserWiseSummaryReport(true);

    this.BranchSummaryReportSortKey = sort.key;
    this.BranchSummaryReportSortValue = sort.value;
    this.getBranchSummaryReport(true);

    this.DepartmentSummaryReportSortKey = sort.key;
    this.DepartmentSummaryReportSortValue = sort.value;
    this.getDepartmentSummaryReport(true);

    this.DesignationSummaryReportSortKey = sort.key;
    this.DesignationSummaryReportSortValue = sort.value;
    this.getDesignationSummaryReport(true);

    this.FolderListReportSortKey = sort.key;
    this.FolderListReportSortValue = sort.value;
    this.getFolderListReport(true);

    this.FileListReportSortKey = sort.key;
    this.FileListReportSortValue = sort.value;
    this.getFileListReport(true);
  }

  branches = [];

  getAllDocProBranches() {
    this.branches = [];

    this.api
      .getDocProBranch(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.branches = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  departments = [];

  getAllDocProDepartments() {
    this.departments = [];

    this.api
      .getDocProDepartments(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.departments = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  designations = [];

  getAllDocProDesignations() {
    this.designations = [];

    this.api
      .getDocProDesignations(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.designations = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  users = [];

  getAllDocProUserName() {
    this.users = [];

    this.api
      .getDocProUserName(
        0,
        0,
        "NAME",
        "asc",
        " AND STATUS=1 AND ORG_ID=" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.users = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 1
  activePanel1(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.UserWiseSummaryReportPageIndex = 1;
      this.UserWiseSummaryReportPageSize = 10;
      this.getUserWiseSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  UserWiseSummaryReportPageIndex = 1;
  UserWiseSummaryReportPageSize = 10;
  UserWiseSummaryReportTotalRecords = 15;
  UserWiseSummaryReportSortValue: string = "desc";
  UserWiseSummaryReportSortKey: string = "ID";
  UserWiseSummaryReportData = [];

  columns1: string[][] = [
    ["OWNER_NAME", "User Name"],
    ["FILE_CREATE_COUNT", "No Of File Created"],
    ["FOLDER_CREATE_COUNT", "No Of Folder Created"],
    ["ALLOCATED_SPACE", "Size Used"],
  ];

  getUserWiseSummaryReport(reset: boolean = false) {
    if (reset) {
      this.UserWiseSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.UserWiseSummaryReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var userId: any = "";
    if (this.OWNER_ID != undefined && this.OWNER_ID.length != 0) {
      userId = this.OWNER_ID;
    }

    this.loadingRecords = true;
    this.api
      .getUserWiseSummaryReport(
        this.UserWiseSummaryReportPageIndex,
        this.UserWiseSummaryReportPageSize,
        this.UserWiseSummaryReportSortKey,
        sort,
        "",
        this.orgId,
        fromDateFilter,
        toDateFilter,
        userId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.UserWiseSummaryReportTotalRecords = data["count"];
            this.UserWiseSummaryReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 2
  activePanel2(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.BranchSummaryReportPageIndex = 1;
      this.BranchSummaryReportPageSize = 10;
      this.getBranchSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  BranchSummaryReportPageIndex = 1;
  BranchSummaryReportPageSize = 10;
  BranchSummaryReportTotalRecords = 15;
  BranchSummaryReportSortValue: string = "desc";
  BranchSummaryReportSortKey: string = "ID";
  BranchSummaryReportData = [];

  columns2: string[][] = [
    ["BRANCH_NAME", "Branch Name"],
    ["FILE_CREATE_COUNT", "No Of File Created"],
    ["FOLDER_CREATE_COUNT", "No Of Folder Created"],
    ["ALLOCATED_SPACE", "Size Used"],
  ];

  getBranchSummaryReport(reset: boolean = false) {
    if (reset) {
      this.BranchSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.BranchSummaryReportSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var BranchFilter: any = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID;
    }

    this.loadingRecords = true;
    this.api
      .getBranchSummaryReport(
        this.BranchSummaryReportPageIndex,
        this.BranchSummaryReportPageSize,
        this.BranchSummaryReportSortKey,
        sort,
        "",
        this.orgId,
        fromDateFilter,
        toDateFilter,
        BranchFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.BranchSummaryReportTotalRecords = data["count"];
            this.BranchSummaryReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 3
  activePanel3(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DepartmentSummaryReportPageIndex = 1;
      this.DepartmentSummaryReportPageSize = 10;
      this.getDepartmentSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DepartmentSummaryReportPageIndex = 1;
  DepartmentSummaryReportPageSize = 10;
  DepartmentSummaryReportTotalRecords = 15;
  DepartmentSummaryReportSortValue: string = "desc";
  DepartmentSummaryReportSortKey: string = "ID";
  DepartmentSummaryReportData = [];

  columns3: string[][] = [
    ["DEPARTMENT_NAME", "Department Name"],
    ["FILE_CREATE_COUNT", "No Of File Created"],
    ["FOLDER_CREATE_COUNT", "No Of Folder Created"],
    ["ALLOCATED_SPACE", "Size Used"],
  ];

  getDepartmentSummaryReport(reset: boolean = false) {
    if (reset) {
      this.DepartmentSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DepartmentSummaryReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
    }

    this.loadingRecords = true;
    this.api
      .getDepartmentSummaryReport(
        this.DepartmentSummaryReportPageIndex,
        this.DepartmentSummaryReportPageSize,
        this.DepartmentSummaryReportSortKey,
        sort,
        "",
        this.orgId,
        fromDateFilter,
        toDateFilter,
        DepartmentFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DepartmentSummaryReportTotalRecords = data["count"];
            this.DepartmentSummaryReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 4
  activePanel4(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.DesignationSummaryReportPageIndex = 1;
      this.DesignationSummaryReportPageSize = 10;
      this.getDesignationSummaryReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  DesignationSummaryReportPageIndex = 1;
  DesignationSummaryReportPageSize = 10;
  DesignationSummaryReportTotalRecords = 15;
  DesignationSummaryReportSortValue: string = "desc";
  DesignationSummaryReportSortKey: string = "ID";
  DesignationSummaryReportData = [];

  columns4: string[][] = [
    ["DESIGNATION_NAME", "Designation Name"],
    ["FILE_CREATE_COUNT", "No Of File Created"],
    ["FOLDER_CREATE_COUNT", "No Of Folder Created"],
    ["ALLOCATED_SPACE", "Size Used"],
  ];

  getDesignationSummaryReport(reset: boolean = false) {
    if (reset) {
      this.DesignationSummaryReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.DesignationSummaryReportSortValue.startsWith("a")
        ? "asc"
        : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var DesignationFilter: any = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID;
    }

    this.loadingRecords = true;
    this.api
      .getDesignationSummaryReport(
        this.DesignationSummaryReportPageIndex,
        this.DesignationSummaryReportPageSize,
        this.DesignationSummaryReportSortKey,
        sort,
        "",
        this.orgId,
        fromDateFilter,
        toDateFilter,
        DesignationFilter
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.DesignationSummaryReportTotalRecords = data["count"];
            this.DesignationSummaryReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 5
  activePanel5(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.FolderListReportPageIndex = 1;
      this.FolderListReportPageSize = 10;
      this.getFolderListReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  FolderListReportPageIndex = 1;
  FolderListReportPageSize = 10;
  FolderListReportTotalRecords = 15;
  FolderListReportSortValue: string = "desc";
  FolderListReportSortKey: string = "ID";
  FolderListReportData = [];

  columns5: string[][] = [
    ["NAME", "Folder Name"],
    ["CREATED_DATE_TIME", "Created Date/Time"],
    ["OWNER_NAME", "User Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
  ];

  getFolderListReport(reset: boolean = false) {
    if (reset) {
      this.FolderListReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.FolderListReportSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var BranchFilter: any = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID;
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
    }

    var DesignationFilter: any = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID;
    }

    var userId: any = "";
    if (this.OWNER_ID != undefined && this.OWNER_ID.length != 0) {
      userId = this.OWNER_ID;
    }

    this.loadingRecords = true;
    this.api
      .getFolderListReport(
        this.FolderListReportPageIndex,
        this.FolderListReportPageSize,
        this.FolderListReportSortKey,
        sort,
        "",
        this.orgId,
        fromDateFilter,
        toDateFilter,
        BranchFilter,
        DepartmentFilter,
        DesignationFilter,
        userId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.FolderListReportTotalRecords = data["count"];
            this.FolderListReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }

  // TABLE 6
  activePanel6(panelStatus: boolean, panelID: number) {
    if (panelID == 0 && panelStatus == true) {
      this.FileListReportPageIndex = 1;
      this.FileListReportPageSize = 10;
      this.getFileListReport(false);
    } else if (panelID == 1 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    } else if (panelID == 2 && panelStatus == true) {
      console.log(panelStatus + " " + panelID);
    }
  }

  FileListReportPageIndex = 1;
  FileListReportPageSize = 10;
  FileListReportTotalRecords = 15;
  FileListReportSortValue: string = "desc";
  FileListReportSortKey: string = "ID";
  FileListReportData = [];

  columns6: string[][] = [
    ["NAME", "File Name"],
    ["CREATED_DATE_TIME", "Created Date/Time"],
    ["OWNER_NAME", "User Name"],
    ["BRANCH_NAME", "Branch Name"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["DESIGNATION_NAME", "Designation Name"],
    // ["ALLOCATED_SPACE", "Size Used"],
  ];

  getFileListReport(reset: boolean = false) {
    if (reset) {
      this.FileListReportPageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.FileListReportSortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var fromDateFilter = "";
    var toDateFilter = "";
    if (this.DATE != undefined && this.DATE.length != 0) {
      fromDateFilter = this.datePipe.transform(this.DATE[0], "yyyy-MM-dd");
      toDateFilter = this.datePipe.transform(this.DATE[1], "yyyy-MM-dd");
    }

    var BranchFilter: any = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID;
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
    }

    var DesignationFilter: any = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID;
    }

    var userId: any = "";
    if (this.OWNER_ID != undefined && this.OWNER_ID.length != 0) {
      userId = this.OWNER_ID;
    }

    this.loadingRecords = true;
    this.api
      .getFileListReport(
        this.FileListReportPageIndex,
        this.FileListReportPageSize,
        this.FileListReportSortKey,
        sort,
        "",
        this.orgId,
        fromDateFilter,
        toDateFilter,
        BranchFilter,
        DepartmentFilter,
        DesignationFilter,
        userId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.FileListReportTotalRecords = data["count"];
            this.FileListReportData = data["data"];
          }
        },
        (err) => {
          if (err["ok"] == false) this.message.error("Server Not Found", "");
        }
      );
  }
}
