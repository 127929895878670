import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { ExportService } from 'src/app/Service/export.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-dashboardreport',
  templateUrl: './dashboardreport.component.html',
  styleUrls: ['./dashboardreport.component.css'],
  providers: [DatePipe]
})

export class DashboardreportComponent implements OnInit {
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  designationId = Number(this.cookie.get('designationId'));
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  dateFormat = 'dd/MM/yyyy';
  DEPARTMENT_ID = [];
  DATE_FILTER: Date[] = [];
  SUPPORT_USER = [];
  departments = [];
  supportusers = [];
  userWisefilterClass: string = "filter-visible";
  userWiseIsFilterApplied: string = "default";

  DATA_TYPE: boolean = true;
  tabularFormatDeptWiseSummary: boolean = true;
  tabularFormatDeptWiseTotalTickets: boolean = true;
  tabularFormatSupportUserWise: boolean = true;
  tabularFormatDeptWiseTimeTakenSummary: boolean = true;

  departmentWiseFormTitle = "Department Wise Summary";
  departmentWisePageIndex = 1;
  departmentWisePageSize = 10;
  departmentWiseTotalRecords = 1;
  departmentWiseDataList = [];
  departmentWiseLoadingRecords = true;
  departmentWiseColumns: string[][] = [["DEPARTMENT_NAME", "Department"], ["CREATED", "Pending"], ["ASSIGNED", "Assigned"], ["ANSWERED", "Answered"], ["RE_OPEN", "Re-Opened"], ["CLOSED", "Closed"], ["BANNED", "Banned"], ["ON_HOLD", "On Hold"]];

  departmentWiseFormTitlePieChart = "Department Wise Total Ticket(s)";
  departmentWisePageIndexPieChart = 1;
  departmentWisePageSizePieChart = 10;
  departmentWiseTotalRecordsPieChart = 1;
  departmentWiseDataListPieChart = [];
  departmentWiseLoadingRecordsPieChart = true;
  departmentWiseColumnsPieChart: string[][] = [["DEPARTMENT_NAME", "Department"], ["TOTAL", "Total Ticket(s)"]];

  userWiseFormTitle = "Support User Wise Summary";
  userWisePageIndex = 1;
  userWisePageSize = 10;
  userWiseTotalRecords = 1;
  userWiseDataList = [];
  userWiseLoadingRecords = true;
  userWiseColumns: string[][] = [["CREATOR_EMPLOYEE_NAME", "Support User"], ["DEPARTMENT_NAME", "Department"], ["CREATED", "Pending"], ["ASSIGNED", "Assigned"], ["ANSWERED", "Answered"], ["RE_OPEN", "Re-Open"], ["CLOSED", "Closed"], ["BANNED", "Banned"], ["ON_HOLD", "On Hold"]];

  departmentWiseTimeTakenFormTitle = "Department Wise Time Taken Summary";
  departmentWiseTimeTakenPageIndex = 1;
  departmentWiseTimeTakenPageSize = 10;
  departmentWiseTimeTakenTotalRecords = 1;
  departmentWiseTimeTakenDataList = [];
  departmentWiseTimeTakenLoadingRecords = true;
  departmentWiseTimeTakenColumns: string[][] = [["TICKET_TAKEN_DEPARTMENT", "Department"], ["CLOSED_BEFORE_24", "Closed Before 24 hrs"], ["CLOSED_BETWEEN_24_48", "Closed Between 24 To 48 hrs"], ["CLOSED_BETWEEN_48_72", "Closed Between 48 To 72 hrs"], ["CLOSED_AFTER_72", "Closed After 72 hrs"]];

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private _exportService: ExportService) { }

  setDateForDateFilter() {
    this.DATE_FILTER = [];
    let currentDate = new Date();
    let previous30thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.DATE_FILTER[0] = new Date(previous30thDayDate);
    this.DATE_FILTER[1] = new Date();
  }

  changeDataType(status: boolean) {
    this.tabularFormatDeptWiseSummary = status;
    this.tabularFormatDeptWiseTotalTickets = status;
    this.tabularFormatSupportUserWise = status;
    this.tabularFormatDeptWiseTimeTakenSummary = status;
  }

  ngOnInit() {
    this.setDateForDateFilter();

    this.departments = [];
    this.api.getAllDepartments(0, 0, 'NAME', 'ASC', ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.departments = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.supportusers = [];
    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=' + this.cookie.get('orgId') + ' AND ID!=1').subscribe(data => {
      if (data['code'] == 200) {
        this.supportusers = data['data'];
      }

    }, err => {
      console.log(err);
    });

    if (this.roleId == 6)
      this.getDepartmentToShowReport();

    if (this.roleId == 4)
      this.getDepartmentSupportAgentWise();

    if ((this.roleId != 4) && (this.roleId != 6)) {
      this.departmentWiseSearch(true);
      this.userWiseSearch(true);
      this.departmentWiseTicketSearch(true);
      this.departmentWiseTimeTakenSearch(true);
    }

    this.userWiseIsFilterApplied = "default";
    this.userWisefilterClass = "filter-invisible";
  }

  showFilter() {
    if (this.userWisefilterClass === "filter-visible")
      this.userWisefilterClass = "filter-invisible";

    else
      this.userWisefilterClass = "filter-visible";
  }

  applyFilter() {
    this.userWiseIsFilterApplied = "default";
    this.userWisefilterClass = "filter-invisible";
    this.departmentWiseSearch(true);
    this.departmentWiseTimeTakenSearch(true);
    this.departmentWiseTicketSearch(true);
    this.userWiseSearch(true);
  }

  clearFilter() {
    this.setDateForDateFilter();
    this.SUPPORT_USER = [];
    this.DEPARTMENT_ID = [];
    this.userWiseIsFilterApplied = "default";
    this.userWisefilterClass = "filter-invisible";
    this.departmentWiseSearch(true);
    this.departmentWiseTimeTakenSearch(true);
    this.departmentWiseTicketSearch(true);
    this.userWiseSearch(true);
  }

  supportAgentWiseDeptArray = [];
  getDepartmentSupportAgentWise() {
    this.supportAgentWiseDeptArray = [];

    this.api.gettickdeskSupportUserMapping(0, 0, 'ID', "ASC", ' AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
      if (data['code'] == 200) {
        var supportAgentWiseDept = data['data'];

        for (var i = 0; i < supportAgentWiseDept.length; i++) {
          this.supportAgentWiseDeptArray.push(supportAgentWiseDept[i]['DEPARTMENT_ID']);
        }

        if (this.roleId == 4) {
          this.departmentWiseSearch(true);
          this.userWiseSearch(true);
          this.departmentWiseTicketSearch(true);
          this.departmentWiseTimeTakenSearch(true);
        }
      }
    });
  }

  deptWiseReport = [];
  getDepartmentToShowReport() {
    this.deptWiseReport = [];

    this.api.gettickdeskDepartmentAdminMapping(0, 0, 'ID', "ASC", ' AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
      if (data['code'] == 200) {
        var departments = data['data'];

        for (var i = 0; i < departments.length; i++) {
          this.deptWiseReport.push(departments[i]['DEPARTMENT_ID']);
        }

        if (this.roleId == 6) {
          this.departmentWiseSearch(true);
          this.userWiseSearch(true);
          this.departmentWiseTicketSearch(true);
          this.departmentWiseTimeTakenSearch(true);
        }
      }
    });
  }

  // Department Wise Summary
  departmentWiseSearch(reset: boolean = false) {
    if (reset) {
      this.departmentWisePageIndex = 1;
    }

    var deptFilter = "";
    if (this.DEPARTMENT_ID.length > 0)
      deptFilter = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";

    var supportAgentWiseDept = "";
    if (this.roleId == 4) {
      if (this.supportAgentWiseDeptArray.length > 0)
        supportAgentWiseDept = " AND DEPARTMENT_ID IN (" + this.supportAgentWiseDeptArray + ")";

      else
        supportAgentWiseDept = "";
    }

    var deptAdminWiseDept = "";
    if (this.roleId == 6) {
      if (this.deptWiseReport.length > 0)
        deptAdminWiseDept = " AND DEPARTMENT_ID IN (" + this.deptWiseReport + ")";

      else
        deptAdminWiseDept = "";
    }

    var dateFilter = "";
    if ((this.DATE_FILTER != undefined) && (this.DATE_FILTER.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.DATE_FILTER[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.DATE_FILTER[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    this.departmentWiseLoadingRecords = true;

    this.api.getDepartmentwiseReport(this.departmentWisePageIndex, this.departmentWisePageSize, "DEPARTMENT_NAME", "asc", deptFilter + supportAgentWiseDept + deptAdminWiseDept + dateFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.departmentWiseLoadingRecords = false;
        this.departmentWiseTotalRecords = data['count'];
        this.departmentWiseDataList = data['data'];
        this.getTicketStatusWiseCount(this.departmentWiseDataList);
        this.createHorizontalBarChart(this.departmentWiseDataList);
      }

    }, err => {
      console.log(err);
    });
  }

  // Department Wise Time Taken To Close
  departmentWiseTimeTakenSearch(reset: boolean = false) {
    if (reset) {
      this.departmentWiseTimeTakenPageIndex = 1;
    }

    var supportAgentWiseDept = "";
    if (this.roleId == 4) {
      if (this.supportAgentWiseDeptArray.length > 0)
        supportAgentWiseDept = " AND DEPARTMENT_ID IN (" + this.supportAgentWiseDeptArray + ")";

      else
        supportAgentWiseDept = "";
    }

    var deptAdminWiseDept = "";
    if (this.roleId == 6) {
      if (this.deptWiseReport.length > 0)
        deptAdminWiseDept = " AND DEPARTMENT_ID IN (" + this.deptWiseReport + ")";

      else
        deptAdminWiseDept = "";
    }

    var dateFilter = "";
    if ((this.DATE_FILTER != undefined) && (this.DATE_FILTER.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.DATE_FILTER[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.DATE_FILTER[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var deptFilter = "";
    if (this.DEPARTMENT_ID.length > 0)
      deptFilter = " AND TICKET_TAKEN_DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";

    this.departmentWiseTimeTakenLoadingRecords = true;

    this.api.getDepartmentWiseTimeTakenToCloseReport(this.departmentWisePageIndex, this.departmentWisePageSize, "DEPARTMENT_NAME", "asc", deptFilter + supportAgentWiseDept + deptAdminWiseDept + dateFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.departmentWiseTimeTakenLoadingRecords = false;
        this.departmentWiseTimeTakenTotalRecords = data['count'];
        this.departmentWiseTimeTakenDataList = data['data'];
        this.createHorizontalBarChartForDeptWiseTimeTaken(this.departmentWiseTimeTakenDataList);
      }

    }, err => {
      console.log(err);
    });
  }

  // Department Wise Total Ticket Summary
  departmentWiseTicketSearch(reset: boolean = false) {
    if (reset) {
      this.departmentWisePageIndexPieChart = 1;
    }

    var deptFilter = "";
    if (this.DEPARTMENT_ID.length > 0)
      deptFilter = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";

    var supportAgentWiseDept = "";
    if (this.roleId == 4) {
      if (this.supportAgentWiseDeptArray.length > 0)
        supportAgentWiseDept = " AND DEPARTMENT_ID IN (" + this.supportAgentWiseDeptArray + ")";

      else
        supportAgentWiseDept = "";
    }

    var deptAdminWiseDept = "";
    if (this.roleId == 6) {
      if (this.deptWiseReport.length > 0)
        deptAdminWiseDept = " AND DEPARTMENT_ID IN (" + this.deptWiseReport + ")";

      else
        deptAdminWiseDept = "";
    }

    var dateFilter = "";
    if ((this.DATE_FILTER != undefined) && (this.DATE_FILTER.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.DATE_FILTER[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.DATE_FILTER[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    this.departmentWiseLoadingRecordsPieChart = true;

    this.api.getDepartmentwiseReport(this.departmentWisePageIndexPieChart, this.departmentWisePageSizePieChart, "DEPARTMENT_NAME", "asc", deptFilter + supportAgentWiseDept + deptAdminWiseDept + dateFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.departmentWiseLoadingRecordsPieChart = false;
        this.departmentWiseTotalRecordsPieChart = data['count'];
        this.departmentWiseDataListPieChart = data['data'];
        this.createHorizontalBarChartForDeptWiseTotalTickets(this.departmentWiseDataListPieChart);
      }

    }, err => {
      console.log(err);
    });
  }

  // Support User Wise Summary
  userWiseSearch(reset: boolean = false, exportToExcel: boolean = false) {
    if (reset) {
      this.userWisePageIndex = 1;
    }

    var supportUserFilter = "";
    if (this.SUPPORT_USER.length > 0)
      supportUserFilter = " AND CREATOR_EMPLOYEE_ID IN (" + this.SUPPORT_USER + ")";

    var deptFilter = "";
    if (this.DEPARTMENT_ID.length > 0)
      deptFilter = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";

    var supportAgentWiseDept = "";
    if (this.roleId == 4) {
      if (this.supportAgentWiseDeptArray.length > 0)
        supportAgentWiseDept = " AND DEPARTMENT_ID IN (" + this.supportAgentWiseDeptArray + ")";

      else
        supportAgentWiseDept = "";
    }

    var deptAdminWiseDept = "";
    if (this.roleId == 6) {
      if (this.deptWiseReport.length > 0)
        deptAdminWiseDept = " AND DEPARTMENT_ID IN (" + this.deptWiseReport + ")";

      else
        deptAdminWiseDept = "";
    }

    var dateFilter = "";
    if ((this.DATE_FILTER != undefined) && (this.DATE_FILTER.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.DATE_FILTER[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.DATE_FILTER[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    this.userWiseLoadingRecords = true;

    this.api.getUserwiseReport(this.userWisePageIndex, this.userWisePageSize, "", "", supportUserFilter + deptFilter + supportAgentWiseDept + deptAdminWiseDept + dateFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.userWiseLoadingRecords = false;
        this.userWiseTotalRecords = data['count'];
        this.userWiseDataList = data['data'];
        this.createHorizontalBarChartForSupportUserWise(this.userWiseDataList);
      }

    }, err => {
      console.log(err);
    });
  }

  TOTAL_TICKETS: number = 0;
  TOTAL_PENDING_TICKETS: number = 0;
  TOTAL_ASSIGNED_TICKETS: number = 0;
  TOTAL_ANSWERED_TICKETS: number = 0;
  TOTAL_REOPENED_TICKETS: number = 0;
  TOTAL_CLOSED_TICKETS: number = 0;
  TOTAL_BANNED_TICKETS: number = 0;
  TOTAL_ONHOLD_TICKETS: number = 0;

  getTicketStatusWiseCount(ticketJSON: any) {
    let totalTickets = 0;
    let totalPendingTickets = 0;
    let totalAssignedTickets = 0;
    let totalAnsweredTickets = 0;
    let totalReOpenedTickets = 0;
    let totalClosedTickets = 0;
    let totalBannedTickets = 0;
    let totalOnHoldTickets = 0;

    for (var i = 0; i < ticketJSON.length; i++) {
      totalTickets = totalTickets + ticketJSON[i]["TOTAL"];
      totalPendingTickets = totalPendingTickets + ticketJSON[i]["CREATED"];
      totalAssignedTickets = totalAssignedTickets + ticketJSON[i]["ASSIGNED"];
      totalAnsweredTickets = totalAnsweredTickets + ticketJSON[i]["ANSWERED"];
      totalReOpenedTickets = totalReOpenedTickets + ticketJSON[i]["RE_OPEN"];
      totalClosedTickets = totalClosedTickets + ticketJSON[i]["CLOSED"];
      totalBannedTickets = totalBannedTickets + ticketJSON[i]["BANNED"];
      totalOnHoldTickets = totalOnHoldTickets + ticketJSON[i]["ON_HOLD"];
    }

    this.TOTAL_TICKETS = totalTickets;
    this.TOTAL_PENDING_TICKETS = totalPendingTickets;
    this.TOTAL_ASSIGNED_TICKETS = totalAssignedTickets;
    this.TOTAL_ANSWERED_TICKETS = totalAnsweredTickets;
    this.TOTAL_REOPENED_TICKETS = totalReOpenedTickets;
    this.TOTAL_CLOSED_TICKETS = totalClosedTickets;
    this.TOTAL_BANNED_TICKETS = totalBannedTickets;
    this.TOTAL_ONHOLD_TICKETS = totalOnHoldTickets;
  }

  // Horizontal bar chart
  public barChartPlugins1 = [
    DataLabelsPlugin
  ];

  public barChartOptions1: ChartOptions = {
    responsive: true
  };

  public barChartType1: ChartType = 'bar';
  public barChartLabels1: string[] = ['P', 'R', 'B'];
  public barChartLegend1 = true;

  public barChartData1: ChartDataSets[] = [
    { data: [1, 2, 3], label: 'Approved', stack: 'a' },
    { data: [1, 2, 3], label: 'Accepted', stack: 'a' },
    { data: [1, 2, 3], label: 'Open', stack: 'a' },
    { data: [1, 2, 3], label: 'In Progress', stack: 'a' },
  ];

  // Horizontal bar chart for dept wise summary
  createHorizontalBarChart(ticketJSON: any) {
    let dataLables = [];
    let pendingData = [];
    let assignedData = [];
    let answeredData = [];
    let reOpenedData = [];
    let closedData = [];
    let bannedData = [];
    let onHoldData = [];

    for (var i = 0; i < ticketJSON.length; i++) {
      dataLables.push(ticketJSON[i]["DEPARTMENT_NAME"]);
    }

    for (var i = 0; i < ticketJSON.length; i++) {
      pendingData.push(ticketJSON[i]["CREATED"]);
      assignedData.push(ticketJSON[i]["ASSIGNED"]);
      answeredData.push(ticketJSON[i]["ANSWERED"]);
      reOpenedData.push(ticketJSON[i]["RE_OPEN"]);
      closedData.push(ticketJSON[i]["CLOSED"]);
      bannedData.push(ticketJSON[i]["BANNED"]);
      onHoldData.push(ticketJSON[i]["ON_HOLD"]);
    }

    let pendingObj = new Object();
    pendingObj["data"] = pendingData;
    pendingObj["label"] = "Pending";
    pendingObj["stack"] = "a";

    let assignedObj = new Object();
    assignedObj["data"] = assignedData;
    assignedObj["label"] = "Assigned";
    assignedObj["stack"] = "a";

    let answeredObj = new Object();
    answeredObj["data"] = answeredData;
    answeredObj["label"] = "Answered";
    answeredObj["stack"] = "a";

    let reOpenedObj = new Object();
    reOpenedObj["data"] = reOpenedData;
    reOpenedObj["label"] = "Re-Opened";
    reOpenedObj["stack"] = "a";

    let closedObj = new Object();
    closedObj["data"] = closedData;
    closedObj["label"] = "Closed";
    closedObj["stack"] = "a";

    let bannedObj = new Object();
    bannedObj["data"] = bannedData;
    bannedObj["label"] = "Banned";
    bannedObj["stack"] = "a";

    let onHoldObj = new Object();
    onHoldObj["data"] = onHoldData;
    onHoldObj["label"] = "On-Hold";
    onHoldObj["stack"] = "a";

    this.barChartLabels2 = dataLables;
    this.barChartData2 = [];
    this.barChartData2.push(Object.assign({}, pendingObj));
    this.barChartData2.push(Object.assign({}, assignedObj));
    this.barChartData2.push(Object.assign({}, answeredObj));
    this.barChartData2.push(Object.assign({}, reOpenedObj));
    this.barChartData2.push(Object.assign({}, closedObj));
    this.barChartData2.push(Object.assign({}, bannedObj));
    this.barChartData2.push(Object.assign({}, onHoldObj));
  }

  public barChartPlugins2 = [];

  public barChartOptions2: ChartOptions = {
    responsive: true,
  };

  public barChartLabels2: Label[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
  public barChartType2: ChartType = 'horizontalBar';
  public barChartLegend2 = true;

  public barChartData2: ChartDataSets[] = [
    { data: [1, 2, 3, 4, 5, 6, 7, 8], label: 'Pending', stack: 'a' },
    { data: [1, 2, 3, 4, 5, 6, 7, 8], label: 'Assigned', stack: 'a' },
  ];

  // Horizontal bar chart for department wise total tickets
  createHorizontalBarChartForDeptWiseTotalTickets(ticketJSON: any) {
    let barChartLabels = [];
    let barChartData = [];

    for (var i = 0; i < ticketJSON.length; i++) {
      barChartLabels.push(ticketJSON[i]["DEPARTMENT_NAME"]);
      barChartData.push(ticketJSON[i]["TOTAL"]);
    }

    this.barChartLabelsDeptWiseTotalTickets = [];
    this.barChartLabelsDeptWiseTotalTickets = barChartLabels;
    this.barChartDataDeptWiseTotalTickets = [];

    let obj1 = new Object();
    obj1["data"] = barChartData;
    obj1["label"] = "Total Ticket(s)";
    obj1["stack"] = "a";

    this.barChartDataDeptWiseTotalTickets.push(Object.assign({}, obj1));
  }

  public barChartPluginsDeptWiseTotalTickets = [];

  public barChartOptionsDeptWiseTotalTickets: ChartOptions = {
    responsive: true,
  };

  public barChartLabelsDeptWiseTotalTickets: Label[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
  public barChartTypeDeptWiseTotalTickets: ChartType = 'horizontalBar';
  public barChartLegendDeptWiseTotalTickets = true;

  public barChartDataDeptWiseTotalTickets: ChartDataSets[] = [
    { data: [1, 2, 3, 4, 5, 6, 7, 8], label: 'Total Ticket(s)', stack: 'a' }
  ];

  // Horizontal bar chart for support user wise summary
  createHorizontalBarChartForSupportUserWise(ticketJSON: any) {
    let dataLables = [];
    let pendingData = [];
    let assignedData = [];
    let answeredData = [];
    let reOpenedData = [];
    let closedData = [];
    let bannedData = [];
    let onHoldData = [];

    for (var i = 0; i < ticketJSON.length; i++) {
      dataLables.push(ticketJSON[i]["CREATOR_EMPLOYEE_NAME"] + " " + ticketJSON[i]["DEPARTMENT_NAME"]);
    }

    for (var i = 0; i < ticketJSON.length; i++) {
      pendingData.push(ticketJSON[i]["CREATED"]);
      assignedData.push(ticketJSON[i]["ASSIGNED"]);
      answeredData.push(ticketJSON[i]["ANSWERED"]);
      reOpenedData.push(ticketJSON[i]["RE_OPEN"]);
      closedData.push(ticketJSON[i]["CLOSED"]);
      bannedData.push(ticketJSON[i]["BANNED"]);
      onHoldData.push(ticketJSON[i]["ON_HOLD"]);
    }

    let pendingObj = new Object();
    pendingObj["data"] = pendingData;
    pendingObj["label"] = "Pending";
    pendingObj["stack"] = "a";

    let assignedObj = new Object();
    assignedObj["data"] = assignedData;
    assignedObj["label"] = "Assigned";
    assignedObj["stack"] = "a";

    let answeredObj = new Object();
    answeredObj["data"] = answeredData;
    answeredObj["label"] = "Answered";
    answeredObj["stack"] = "a";

    let reOpenedObj = new Object();
    reOpenedObj["data"] = reOpenedData;
    reOpenedObj["label"] = "Re-Opened";
    reOpenedObj["stack"] = "a";

    let closedObj = new Object();
    closedObj["data"] = closedData;
    closedObj["label"] = "Closed";
    closedObj["stack"] = "a";

    let bannedObj = new Object();
    bannedObj["data"] = bannedData;
    bannedObj["label"] = "Banned";
    bannedObj["stack"] = "a";

    let onHoldObj = new Object();
    onHoldObj["data"] = onHoldData;
    onHoldObj["label"] = "On-Hold";
    onHoldObj["stack"] = "a";

    this.barChartLabelsForSupportUserWise = [];
    this.barChartLabelsForSupportUserWise = dataLables;
    this.barChartDataForSupportUserWise = [];
    this.barChartDataForSupportUserWise.push(Object.assign({}, pendingObj));
    this.barChartDataForSupportUserWise.push(Object.assign({}, assignedObj));
    this.barChartDataForSupportUserWise.push(Object.assign({}, answeredObj));
    this.barChartDataForSupportUserWise.push(Object.assign({}, reOpenedObj));
    this.barChartDataForSupportUserWise.push(Object.assign({}, closedObj));
    this.barChartDataForSupportUserWise.push(Object.assign({}, bannedObj));
    this.barChartDataForSupportUserWise.push(Object.assign({}, onHoldObj));
  }

  public barChartPluginsForSupportUserWise = [];

  public barChartOptionsForSupportUserWise: ChartOptions = {
    responsive: true,
  };

  public barChartLabelsForSupportUserWise: Label[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
  public barChartTypeForSupportUserWise: ChartType = 'horizontalBar';
  public barChartLegendForSupportUserWise = true;

  public barChartDataForSupportUserWise: ChartDataSets[] = [
    { data: [1, 2, 3, 8, 5, 6, 7, 8], label: 'Pending', stack: 'a' },
    { data: [1, 2, 3, 4, 5, 6, 7, 8], label: 'Assigned', stack: 'a' },
  ];

  // Horizontal bar chart for dept wise time taken to close
  createHorizontalBarChartForDeptWiseTimeTaken(ticketJSON: any) {
    let dataLables = [];
    let closedBefore24Data = [];
    let closedBetween24_48Data = [];
    let closedBetween48_72Data = [];
    let closedAfter48Data = [];

    for (var i = 0; i < ticketJSON.length; i++) {
      dataLables.push(ticketJSON[i]["TICKET_TAKEN_DEPARTMENT"]);
    }

    for (var i = 0; i < ticketJSON.length; i++) {
      closedBefore24Data.push(ticketJSON[i]["CLOSED_BEFORE_24"]);
      closedBetween24_48Data.push(ticketJSON[i]["CLOSED_BETWEEN_24_48"]);
      closedBetween48_72Data.push(ticketJSON[i]["CLOSED_BETWEEN_48_72"]);
      closedAfter48Data.push(ticketJSON[i]["CLOSED_AFTER_72"]);
    }

    let closedBefore24Obj = new Object();
    closedBefore24Obj["data"] = closedBefore24Data;
    closedBefore24Obj["label"] = "Closed Before 24";
    closedBefore24Obj["stack"] = "a";

    let closedBetween24To48Obj = new Object();
    closedBetween24To48Obj["data"] = closedBetween24_48Data;
    closedBetween24To48Obj["label"] = "Closed Between 24 To 48";
    closedBetween24To48Obj["stack"] = "a";

    let closedBetween48To72Obj = new Object();
    closedBetween48To72Obj["data"] = closedBetween48_72Data;
    closedBetween48To72Obj["label"] = "Closed Between 48 To 72";
    closedBetween48To72Obj["stack"] = "a";

    let closedAfter72Obj = new Object();
    closedAfter72Obj["data"] = closedAfter48Data;
    closedAfter72Obj["label"] = "Closed After 72";
    closedAfter72Obj["stack"] = "a";

    this.barChartLabelsForDeptWiseTimeTaken = dataLables;
    this.barChartDataForDeptWiseTimeTaken = [];
    this.barChartDataForDeptWiseTimeTaken.push(Object.assign({}, closedBefore24Obj));
    this.barChartDataForDeptWiseTimeTaken.push(Object.assign({}, closedBetween24To48Obj));
    this.barChartDataForDeptWiseTimeTaken.push(Object.assign({}, closedBetween48To72Obj));
    this.barChartDataForDeptWiseTimeTaken.push(Object.assign({}, closedAfter72Obj));
  }

  public barChartPluginsForDeptWiseTimeTaken = [];

  public barChartOptionsForDeptWiseTimeTaken: ChartOptions = {
    responsive: true,
  };

  public barChartLabelsForDeptWiseTimeTaken: Label[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
  public barChartTypeForDeptWiseTimeTaken: ChartType = 'horizontalBar';
  public barChartLegendForDeptWiseTimeTaken = true;

  public barChartDataForDeptWiseTimeTaken: ChartDataSets[] = [
    { data: [1, 2, 3, 4, 5, 6, 7, 8], label: 'Pending', stack: 'a' },
    { data: [1, 2, 3, 4, 5, 6, 7, 8], label: 'Assigned', stack: 'a' },
  ];
}