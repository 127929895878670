// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    // Kredpool Server
    apiKey: "AIzaSyCYzZPrHfbMl2RD-dJ7DPSyjq4xWIwgsO0",
    authDomain: "tickdeskkredpool.firebaseapp.com",
    projectId: "tickdeskkredpool",
    storageBucket: "tickdeskkredpool.appspot.com",
    messagingSenderId: "203504887770",
    appId: "1:203504887770:web:672fde2f995f5e2b10c2e3",
    measurementId: "G-VC9QEC9HV8",

    // Beed server
    // apiKey: "AIzaSyAOsHZ6EqSt-yJm83-PU2ZO_rGPpUnhReE",
    // authDomain: "tickdesk-de93d.firebaseapp.com",
    // projectId: "tickdesk-de93d",
    // storageBucket: "tickdesk-de93d.appspot.com",
    // messagingSenderId: "364226150066",
    // appId: "1:364226150066:web:203a0cf2fd5225dba753ab",
    // measurementId: "G-HWX8G137MJ",

    appVersion1: require('../../package.json').version + '-dev',
    appVersion2: require('../../package.json').version,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
